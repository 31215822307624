import { HeartOutlined } from "@ant-design/icons";
import { Footer } from "antd/lib/layout/layout";
import React from "react";
import { Link } from "react-router-dom";

const AirFooter = () => {
  return (
    <Footer className="footer">
      <div>
        <p className="text-small text-gray-400 text-center">
          <span className="text-emerald-600 font-semibold">
            &copy; Galaxy Foundation
          </span>
          {", "}2021 . All rights reserved. <br />
          Developed by{" "}
          <Link
            to={{ pathname: "https://softmile.com.au" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Softmile &nbsp;
          </Link>
          with <HeartOutlined /> React
        </p>
      </div>
    </Footer>
  );
};

export default AirFooter;
