import { Row, Col, Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";

const Istatistic = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [reports, setReports] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStats().then((data) => {
        console.log("aaa", data.data);
        setReports(data.data);
      });
    }
  }, [initialRender]);

  return (
    <div>
      {reports && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            border: "1px solid #ddd",
          }}
        >
          <p className="text-emerald-600 text-3xl font-extrabold mb-6">
            Dashboard
          </p>
          <Row gutter={[6, 6]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/companies")}
              >
                <Statistic
                  title="
    Companies"
                  value={reports.companies}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/topics")}
              >
                <Statistic
                  title="
    Topics"
                  value={reports.topics}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/events")}
              >
                <Statistic
                  title="
    Resources"
                  value={reports.resources}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
    Files"
                  value={reports.files}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
    Links"
                  value={reports.links}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
    Videos"
                  value={reports.videos}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
    Grades"
                  value={reports.grades}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/terms")}
              >
                <Statistic
                  title="Terms"
                  value={reports.terms}
                  valueStyle={{
                    fontWeight: "bold",
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/terms")}
              >
                <Statistic
                  title="Weeks"
                  value={reports.weeks}
                  valueStyle={{
                    fontWeight: "bold",
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/weeklyreports")}
              >
                <Statistic
                  title="Reports"
                  value={reports.reports}
                  valueStyle={{
                    fontWeight: "bold",
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/grades")}
              >
                <Statistic
                  title="Mentors"
                  value={reports.mentors}
                  valueStyle={{
                    fontWeight: "bold",
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/students")}
              >
                <Statistic
                  title="Students"
                  value={reports.students}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Parents"
                  value={reports.parents}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/alerts")}
              >
                <Statistic
                  title="
    Alerts"
                  value={reports.alarms}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/admin/contacts")}
              >
                <Statistic
                  title="
    Contacts"
                  value={reports.contacts}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Istatistic;
