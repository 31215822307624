import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import {
  ApartmentOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DragOutlined,
  GroupOutlined,
  HeartOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PicLeftOutlined,
  SendOutlined,
  TeamOutlined,
  UndoOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import AdminPage from "../components/admin/AdminPage";
import Companies from "src/components/admin/Companies";
import Grades from "src/components/admin/Grades";
import Topics from "src/components/admin/Topics";
import Terms from "src/components/admin/Terms";
import TermSettings from "src/components/admin/TermSettings";
import TopicAssignments from "src/components/admin/TopicAssignments";
import Mentors from "src/components/admin/Mentors";
import Students from "src/components/admin/Students";
import TopicDetails from "src/components/admin/TopicDetails";
import TopicComplete from "src/components/admin/TopicComplete";
import WeeklyReports from "src/components/admin/WeeklyReports";
import ChangeMentor from "src/components/admin/ChangeMentor";
import { GlobalContext } from "src/context/GlobalContext";
import MentorReports from "src/components/admin/MentorReports";
import Alerts from "src/components/admin/Alerts";
import Contacts from "src/components/admin/Contacts";
import SendEmail from "src/components/admin/SendEmail";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const { logo } = useContext(GlobalContext);
  const location = useLocation();
  const router = useHistory();
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            {logo && (
              <img
                onClick={() => router.push("/home/homepage")}
                src={logo}
                alt="logo"
                style={{
                  maxWidth: "180px",
                  marginLeft: !sm ? "20px" : "",
                  cursor: "pointer",
                }}
              />
            )}
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  localStorage.clear();
                  router.push("/");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
              <Link to="/admin/adminpage">Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="companies" icon={<ApartmentOutlined />}>
              <Link to="/admin/companies">Companies</Link>
            </Menu.Item>
            <Menu.Item key="grades" icon={<OrderedListOutlined />}>
              <Link to="/admin/grades">Groups</Link>
            </Menu.Item>
            <Menu.Item key="terms" icon={<CalendarOutlined />}>
              <Link to="/admin/terms">Terms</Link>
            </Menu.Item>
            <Menu.Item key="topics" icon={<PicLeftOutlined />}>
              <Link to="/admin/topics">Topics</Link>
            </Menu.Item>
            <Menu.Item key="assignments" icon={<DragOutlined />}>
              <Link to="/admin/assignments">Schedule Topic</Link>
            </Menu.Item>
            <Menu.Item key="mentors" icon={<UserAddOutlined />}>
              <Link to="/admin/mentors">Mentors</Link>
            </Menu.Item>
            <Menu.Item key="students" icon={<TeamOutlined />}>
              <Link to="/admin/students">Students</Link>
            </Menu.Item>
            <Menu.Item key="changementor" icon={<UndoOutlined />}>
              <Link to="/admin/changementor">Change Mentor</Link>
            </Menu.Item>
            <Menu.Item key="alerts" icon={<NotificationOutlined />}>
              <Link to="/admin/alerts">Alerts</Link>
            </Menu.Item>
            <Menu.Item key="weeklyreports" icon={<LineChartOutlined />}>
              <Link to="/admin/weeklyreports">Weekly Reports</Link>
            </Menu.Item>
            <Menu.Item key="mentorreports" icon={<LineChartOutlined />}>
              <Link to="/admin/mentorreports">Mentor Reports</Link>
            </Menu.Item>
            <Menu.Item key="sendemail" icon={<SendOutlined />}>
              <Link to="/admin/sendemail">Send Email</Link>
            </Menu.Item>
            <Menu.Item key="contacts" icon={<MailOutlined />}>
              <Link to="/admin/contacts">Contacts</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/adminpage" />
            </Route>
            <Route path="/admin/adminpage" component={AdminPage} />
            <Route path="/admin/companies" component={Companies} />
            <Route path="/admin/grades" component={Grades} />
            <Route path="/admin/topics" component={Topics} />
            <Route path="/admin/terms/:id/settings" component={TermSettings} />
            <Route path="/admin/terms" component={Terms} />
            <Route path="/admin/assignments" component={TopicAssignments} />
            <Route path="/admin/mentors" component={Mentors} />
            <Route path="/admin/students" component={Students} />
            <Route path="/admin/weeklyreports" component={WeeklyReports} />
            <Route path="/admin/mentorreports" component={MentorReports} />
            <Route path="/admin/changementor" component={ChangeMentor} />
            <Route path="/admin/alerts" component={Alerts} />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/sendemail" component={SendEmail} />

            <Route
              path="/admin/week/:wid/topic/:id/details"
              component={TopicDetails}
            />
            <Route
              path="/admin/week/:wid/topic/:id/complete"
              component={TopicComplete}
            />

            {/* <Route path="/staff/contacts" component={Contacts} />
            <Route path="/staff/newsletters" component={Newsletters} />
            <Route path="/staff/forms" component={Forms} />
           */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            <span className="text-emerald-600 font-semibold">
              &copy; Galaxy Foundation
            </span>
            {", "}2021 . All rights reserved. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
