import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, List, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory, useLocation } from "react-router";
import { AdminService } from "src/resources/AdminService";

const SearchResult = () => {
  const location = useLocation();
  const { text } = location.state;
  const [initialRender, setInitialRender] = useState(true);
  const [isOk, setIsOk] = useState(false);
  const [topics, setTopics] = useState([]);
  const [resources, setResources] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.searchText(text).then((data) => {
        console.log(data.data);
        setTopics(data.data.topics);
        setResources(data.data.resources);
        setIsOk(true);
      });
    }
  }, [initialRender, text]);
  const getDetails = (tip, id) => {
    console.log(tip, id);
    if (tip === "topic") {
      router.push(`/home/search/topic/${id}/details`);
    } else {
      router.push(`/home/search/resource/${id}/details`);
    }
  };
  return (
    <div className="container mx-auto max-w-4xl ">
      <PageHeader
        backIcon={
          <Button size="small" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        }
        className="site-page-header-responsive"
        onBack={() => router.goBack()}
        title={`Search results : "${text}"`}
      />
      {isOk && (
        <>
          <List
            header={
              <span style={{ marginLeft: "15px", fontWeight: "bold" }}>
                The word you searched for was found in {topics.length} TOPIC/S.
              </span>
            }
            style={{
              paddingBottom: "10px",
              marginBottom: "20px",
              backgroundColor: "white",
            }}
            size="small"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 10,
              hideOnSinglePage: true,
              position: "both",
            }}
            itemLayout="horizontal"
            dataSource={topics}
            renderItem={(item, index) => (
              <List.Item
                extra={
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => getDetails("topic", item.id)}
                  >
                    Get Details
                  </Button>
                }
              >
                <List.Item.Meta
                  title={`Result ${index + 1} / Topic`}
                  description={
                    <div>
                      <p>Name</p>
                      <Highlighter
                        unhighlightStyle={{
                          color: "#333333",
                        }}
                        highlightClassName="highlight-class"
                        searchWords={[text]}
                        autoEscape={false}
                        textToHighlight={item.name || "-"}
                      />
                      <p>Description</p>
                      <Highlighter
                        unhighlightStyle={{
                          color: "#333333",
                        }}
                        highlightClassName="highlight-class"
                        searchWords={[text]}
                        autoEscape={false}
                        textToHighlight={item.description || "-"}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          <List
            header={
              <span style={{ marginLeft: "15px", fontWeight: "bold" }}>
                The word you searched for was found in {topics.length}{" "}
                RESOURCE/S.
              </span>
            }
            style={{
              paddingBottom: "10px",
              marginBottom: "20px",
              backgroundColor: "white",
            }}
            size="small"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 10,
              hideOnSinglePage: true,
              position: "both",
            }}
            itemLayout="horizontal"
            dataSource={resources}
            renderItem={(item, index) => (
              <List.Item
                extra={
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => getDetails("resource", item.id)}
                  >
                    Get Details
                  </Button>
                }
              >
                <List.Item.Meta
                  title={`Result ${index + 1} / Resources (${item.doctype})`}
                  description={
                    <div>
                      <p>Title</p>
                      <Highlighter
                        unhighlightStyle={{
                          color: "#333333",
                        }}
                        highlightClassName="highlight-class"
                        searchWords={[text]}
                        autoEscape={false}
                        textToHighlight={item.title || "-"}
                      />
                      <p>Description</p>
                      <Highlighter
                        unhighlightStyle={{
                          color: "#333333",
                        }}
                        highlightClassName="highlight-class"
                        searchWords={[text]}
                        autoEscape={false}
                        textToHighlight={item.description || "-"}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
};

export default SearchResult;
