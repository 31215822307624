import { Divider, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const TermSettings = () => {
  const location = useLocation();
  // @ts-ignore
  const { item } = location.state || { item: null };
  const [weeks] = useState(item.weeks);
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      render: (no) => "Week-" + no,
    },
    {
      title: "Start Date",
      dataIndex: "start",
      render: (start) => moment(start).format("DD-MM-YY ddd"),
    },
    {
      title: "End Date",
      dataIndex: "end",
      render: (end) => moment(end).format("DD-MM-YY ddd"),
    },
  ];
  return (
    <div>
      {" "}
      {weeks && (
        <div>
          <br />

          <Divider orientation="left">Planned Weeks</Divider>
          <Table dataSource={weeks} size="small" bordered columns={columns} />
        </div>
      )}
    </div>
  );
};

export default TermSettings;
