import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Upload,
} from "antd";
import React, { useState } from "react";
import countries from "src/resources/countries.json";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

function NewCompany({ sir, newPanel }) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [states, setStates] = useState(GlobalVariables.auStates);

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await GlobalVariables.getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onFinish = (values) => {
    if (fileList.length < 1) {
      notification.error({
        message: "Error!",
        description: "Please add logo",
      });
      return;
    }
    console.log(values);
    values = { ...values, logo: fileList[0].response.url };
    AdminService.newCompany(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Company added successfully.",
      });
      onReset();
      sir(true);
      newPanel(false);
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };
  function onChange(value) {
    const acountry = countries.filter((c) => {
      return c.code3 === value;
    });
    setStates(acountry[0].states);
    form.setFieldsValue({ state: null });
  }
  return (
    <div className="max-w-5xl">
      <Divider>New Company Form</Divider>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        {...GlobalVariables.formLayout}
        initialValues={{ country: "AUS", state: "NSW" }}
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[{ required: true, message: "Company name required" }]}
        >
          <Input placeholder="Please type company name" />
        </Form.Item>
        <Form.Item
          label="Company Formal Name"
          name="formalName"
          rules={[{ required: true, message: "Company formal name required" }]}
        >
          <Input placeholder="Type company formal name" />
        </Form.Item>
        <Form.Item label="Company Logo">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : GlobalVariables.uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
          <small>Aspect ratio must be 1:1</small>
        </Form.Item>
        <Form.Item
          label="First Phone"
          name="phone1"
          rules={[{ required: true, message: "Phone required" }]}
        >
          <Input placeholder="Please type first phone" />
        </Form.Item>
        <Form.Item
          label="Second Phone"
          name="phone2"
          rules={[{ required: true, message: "Second phone required" }]}
        >
          <Input placeholder="Please type second phone" />
        </Form.Item>
        <Form.Item
          label="Company Email"
          name="cEmail"
          rules={[{ required: true, message: "Company Email required" }]}
        >
          <Input placeholder="Please type company email" />
        </Form.Item>

        <Form.Item name="address" label="Address">
          <Input placeholder="Please Type Address" />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }} {...GlobalVariables.tailLayout}>
          <Form.Item
            name="suburb"
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            rules={[{ required: true, message: "Address is required" }]}
          >
            <Input placeholder="Suburb" />
          </Form.Item>

          <Form.Item
            name="postcode"
            rules={[{ required: true, message: "Postcode is required" }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 0px)",
              margin: "0 0 0 8px",
            }}
          >
            <Input placeholder="Postcode" />
          </Form.Item>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }} {...GlobalVariables.tailLayout}>
          <Form.Item
            name="country"
            rules={[{ required: true, message: "Country is required" }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Select
              showSearch
              placeholder="Select Country"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((c) => (
                <Select.Option key={c.code3} value={c.code3}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="state"
            rules={[{ required: true, message: "State is required" }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 0px)",
              margin: "0 0 0 8px",
            }}
          >
            <Select placeholder="Select State">
              {states.map((s) => (
                <Select.Option key={s.code} value={s.code}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Facebook" name="facebook">
          <Input placeholder="Please type Facebook link" />
        </Form.Item>
        <Form.Item label="Twitter" name="twitter">
          <Input placeholder="Please type Twitter link" />
        </Form.Item>
        <Form.Item label="Instagram" name="instagram">
          <Input placeholder="Please type Instagram link" />
        </Form.Item>
        <Divider>Company Domains</Divider>
        <Form.List
          name="domains"
          rules={[
            {
              validator: async (_, domains) => {
                if (!domains || domains.length < 2) {
                  return Promise.reject(
                    new Error("type min 2 domains (with/without www)")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? GlobalVariables.formItemLayout
                    : GlobalVariables.formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Domain" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Type domain address or delete this line",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="eg. ->  https://www.softmile.com.au"
                      style={{ width: "70%" }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item {...GlobalVariables.tailLayout}>
                <Button
                  type="dashed"
                  className="!bg-white"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add Domain Address
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider>Company site manager (Admin)</Divider>
        <Form.Item
          label="Admin email address"
          name="email"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input placeholder="Please type admin email" />
        </Form.Item>
        <Form.Item
          label="Admin Fullname"
          name="fullname"
          rules={[{ required: true, message: "Admin fullname required" }]}
        >
          <Input placeholder="Please type admin fullname" />
        </Form.Item>
        <Form.Item
          label="Admin mobile phone"
          name="gsm"
          rules={[{ required: true, message: "Mobile phone required" }]}
        >
          <Input placeholder="Please type admin phone" />
        </Form.Item>
        <Form.Item
          label="Admin Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password required!",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
              message:
                "Your password must be 6-25 digits, contain letters and numbers",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Admin password again"
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords did not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Password again" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button htmlType="reset">Clear</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NewCompany;
