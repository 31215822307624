import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  },
  formItemLayoutWithOutLabel: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 8 },
    },
  },
  getMyRole: () => {
    return JSON.parse(localStorage.getItem("user")).role;
  },
  getBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  uploadButton: (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  ),
  fileButton: (name) => (
    <div>
      <FileOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">{name}</div>
    </div>
  ),
  createMarkup: (gelenDetay) => {
    return {
      __html: gelenDetay,
    };
  },
  auStates: [
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ],
};
