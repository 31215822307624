import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  FileOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Divider, PageHeader, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory, useParams } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const TopicDetails = () => {
  // @ts-ignore
  const { id, wid } = useParams();
  const router = useHistory();
  const { TabPane } = Tabs;
  const [initialRender, setInitialRender] = useState(true);
  const [topic, setTopic] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getTopicDetails(id).then((data) => {
        console.log(data.data);
        data.data.resources.length > 0 &&
          setActiveKey(data.data.resources[0].key.toString());
        setTopic(data.data);
      });
    }
  }, [id, initialRender]);
  const onTabChange = (key) => {
    setActiveKey(key.toString());
  };
  const download = (url) => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_FILES_BASEURL + url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="max-w-4xl mx-auto">
      {topic && (
        <div>
          <PageHeader
            backIcon={
              <Button size="small" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            }
            className="site-page-header-responsive"
            onBack={() => router.goBack()}
            title={`Topic : ${topic.name}`}
            extra={[
              <Tag key="2" color="blue">{`${topic.grade}`}</Tag>,
              <Button
                icon={<CheckOutlined />}
                key="1"
                type="primary"
                onClick={() =>
                  router.push(`/home/week/${wid}/topic/${topic.id}/complete`, {
                    name: topic.name,
                  })
                }
              >
                Mark as complete
              </Button>,
            ]}
            footer={<p>{topic.description}</p>}
          />
          <br />
          <div
            className="p-2 text-base"
            dangerouslySetInnerHTML={GlobalVariables.createMarkup(
              topic.details
            )}
          />
          <br />
          <br />
          {activeKey && (
            <div className="bg-fafafa p-4 rounded border border-gray-300">
              <Divider orientation="left">Resources on this topic</Divider>
              <Tabs hideAdd onChange={onTabChange} activeKey={activeKey}>
                {topic.resources.map((pane) => (
                  <TabPane
                    tab={
                      <span>
                        {activeKey === pane.key.toString() ? (
                          <ArrowDownOutlined />
                        ) : (
                          <ArrowRightOutlined />
                        )}
                        {pane.docType}
                      </span>
                    }
                    key={pane.key}
                  >
                    <div className="border border-gray-300 bg-white p-2">
                      <p className="text-base text-mavi"> {pane.title || ""}</p>
                      <p className="text-gray-600 mt-1">
                        {pane.description || ""}
                      </p>
                      <br />
                      <div
                        className="text-base"
                        dangerouslySetInnerHTML={GlobalVariables.createMarkup(
                          pane.details
                        )}
                      />
                      <br />
                    </div>

                    {pane.files.length > 0 && (
                      <div>
                        <Divider orientation="left">Resource Files</Divider>
                        {pane.files.map((f) => (
                          <Button
                            onClick={() => download(f.url)}
                            className="text-left mb-2 "
                            size="large"
                            block
                            key={f.id}
                            icon={<FileOutlined />}
                          >
                            {f.name}
                          </Button>
                        ))}
                      </div>
                    )}
                    <br />
                    {pane.links.length > 0 && (
                      <div>
                        <Divider orientation="left">
                          Resource External Links
                        </Divider>
                        {pane.links.map((f) => (
                          <Button
                            onClick={() => window.open(f.url, "_blank")}
                            className="text-left mb-2"
                            size="large"
                            block
                            key={f.id}
                            icon={<LinkOutlined />}
                          >
                            {f.title}
                          </Button>
                        ))}
                      </div>
                    )}
                    <br />
                    {pane.videos.length > 0 && (
                      <div>
                        <Divider orientation="left">Resource Videos</Divider>
                        {pane.videos.map((f) => (
                          <div key={f.id}>
                            <p>{f.title}</p>
                            <div className="player-wrapper">
                              <ReactPlayer
                                url={f.url}
                                className="react-player"
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          )}
        </div>
      )}
      <br />
      <br />
    </div>
  );
};

export default TopicDetails;
