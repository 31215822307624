import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import Istatistic from "./Istatistic";

const AdminPAge = () => {
  return (
    <div>
      <Istatistic />
    </div>
  );
};

export default AdminPAge;
