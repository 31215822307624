import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ClearOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  PageHeader,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";

const TopicComplete = () => {
  // @ts-ignore
  const { id, wid } = useParams();
  const location = useLocation();
  const { name } = location.state;
  const router = useHistory();
  const [form] = Form.useForm();
  const [initialId, setInitialId] = useState(null);
  const [students, setStudents] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isOk, setIsOk] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    AdminService.getMentorStudents(
      JSON.parse(localStorage.getItem("user")).id
    ).then((data) => {
      console.log(data.data);
      setStudents(data.data);
    });
    user &&
      AdminService.getReport(wid, id, user.id).then((data) => {
        console.log("test", data.data);
        if (data.data) {
          setInitialId(data.data.id);
          const ogrenciler = [];
          data.data.students.map((s) => {
            ogrenciler.push(s.value);
          });

          setInitialValues({
            coordinator: data.data.cmessage,
            parents: data.data.pmessage,
            students: data.data.smessage,
            report: ogrenciler,
          });
          console.log("grenci:", ogrenciler);
          setCheckedList(ogrenciler);
        }
        setIsOk(true);
      });
  }, [id, user, wid]);

  const onFinish = (v) => {
    console.log(v);
    const req = {
      cmessage: v.coordinator,
      smessage: v.students,
      pmessage: v.parents,
      students: v.report,
      tid: id,
      wid,
      id: initialId,
    };
    console.log("REQ", req);
    AdminService.newReport(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Report saved successfully!",
      });
      onReset();
      router.goBack();
    });
  };
  const onReset = () => {
    form.resetFields();
    setCheckedList([]);
    setIndeterminate(false);
    setCheckAll(false);
  };
  const onChange = (list) => {
    console.log(list);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < students.length);
    setCheckAll(list.length === students.length);
  };
  const onCheckAllChange = (e) => {
    console.log(e);
    setCheckedList(
      e.target.checked
        ? students.map((s) => {
            return s.value;
          })
        : []
    );
    e.target.checked
      ? form.setFieldsValue({
          report: students.map((s) => {
            return s.value;
          }),
        })
      : form.setFieldsValue({ report: [] });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <div className="max-w-4xl mx-auto">
      <PageHeader
        backIcon={
          <Button size="small" icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        }
        className="site-page-header-responsive"
        onBack={() => router.goBack()}
        title={
          <span className="text-emerald-600">
            <ArrowRightOutlined /> {name}
          </span>
        }
        // extra={[
        //   <Button
        //     icon={<CheckOutlined />}
        //     key="1"
        //     type="primary"
        //     onClick={() => {}}
        //   >
        //     Save as complete
        //   </Button>,
        // ]}
        footer={
          <p className="text-base font-semibold">Please Complete This Form</p>
        }
      />
      <br />

      {isOk && (
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
          onReset={onReset}
          layout="vertical"
          className="p-4 border border-gray-300 bg-f5f5f5 shadow-lg"
        >
          <Form.Item>
            <p className="font-semibold text-emerald-600">
              Write your message about your program this week. These messages
              will be forwarded to the relevant persons.{" "}
            </p>
          </Form.Item>
          <Form.Item
            label="Message to your Coordinator"
            name="coordinator"
            rules={[{ required: true, message: "This field required!" }]}
          >
            <Input.TextArea placeholder="Type message here" />
          </Form.Item>
          <Form.Item
            label="Message to your Students"
            name="students"
            rules={[{ required: true, message: "This field required!" }]}
          >
            <Input.TextArea placeholder="Type message here" />
          </Form.Item>
          <Form.Item
            label="Message to your Student Parents"
            name="parents"
            rules={[{ required: true, message: "This field required!" }]}
          >
            <Input.TextArea placeholder="Type message here" />
          </Form.Item>
          {students && (
            <>
              <Form.Item
                label={<span className="text-base">Participation Report</span>}
                className="-mb-2"
              >
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Check all
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="report"
                rules={[
                  {
                    required: true,
                    message: "Please select the participating students",
                  },
                ]}
              >
                <Checkbox.Group
                  options={students}
                  value={checkedList}
                  onChange={onChange}
                />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}

      <br />
      <br />
    </div>
  );
};

export default TopicComplete;
