import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "src/resources/AdminService";

const SendEmail = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [maillist, setMaillist] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [quillValue, setQuillValue] = useState(null);
  const [grup, setGrup] = useState(1);
  const { confirm } = Modal;

  const quillChange = (e) => {
    setQuillValue(e);
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudents("Student").then((data) => {
        console.log(data.data);
        var tmp = [];
        data.data.map((student) => {
          var item = {
            student: student.fullname,
            email: student.email,
            id: student.id,
          };
          if (student.parents.length === 0) {
            item = {
              ...item,
              parent: null,
              pemail: null,
            };
          }
          if (student.parents.length === 1) {
            item = {
              ...item,
              parent: student.parents[0].fullname || null,
              pemail: student.parents[0].email || null,
            };
          }
          if (student.parents.length > 1) {
            item = {
              ...item,
              parent: student.parents[0].fullname || null,
              pemail: student.parents[0].email || null,
              parent2: student.parents[1].fullname || null,
              pemail2: student.parents[1].email || null,
            };
          } else {
            item = { ...item, parent2: null, pemail2: null };
          }
          tmp.push(item);
        });
        console.log(tmp);
        setMaillist(tmp);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Student",
      dataIndex: "student",
    },
    {
      title: "Student email",
      dataIndex: "email",
    },
    {
      title: "Parent",
      dataIndex: "parent",
    },
    {
      title: "Parent email",
      dataIndex: "pemail",
    },
    {
      title: "Parent 2",
      dataIndex: "parent2",
    },
    {
      title: "Parent-2 email",
      dataIndex: "pemail2",
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const grupChanged = (v) => {
    setGrup(v.target.value);
  };
  const sendEmail = () => {
    console.log(selectedRowKeys, grup);
    const tmp = [];
    selectedRowKeys.forEach((row) => {
      var item = maillist[maillist.findIndex((m) => m.id === row)];
      switch (grup) {
        case 1:
          item.email !== null &&
            item.email.includes("@") &&
            tmp.push(item.email);
          item.pemail !== null &&
            item.pemail.includes("@") &&
            tmp.push(item.pemail);
          item.pemail2 !== null &&
            item.pemail2.includes("@") &&
            tmp.push(item.pemail2);
          break;
        case 2:
          item.email !== null &&
            item.email.includes("@") &&
            tmp.push(item.email);
          break;
        case 3:
          item.pemail !== null &&
            item.pemail.includes("@") &&
            tmp.push(item.pemail);
          item.pemail2 !== null &&
            item.pemail2.includes("@") &&
            tmp.push(item.pemail2);
          break;

        default:
          break;
      }
    });
    console.log(tmp);
    console.log(quillValue);
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>Send email List:</p>
          {tmp.join(",  ")}
        </div>
      ),
      onOk() {
        if (tmp.length === 0 || quillValue === null || quillValue === "") {
          notification.error({
            message: "Please select student or type email content",
          });
          return;
        }

        var obj = {
          emails: tmp,
          email: quillValue,
        };
        AdminService.sendEmail(obj).then((data) => {
          notification.success({ message: "Email Sent!" });
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div>
      <br />
      {hasSelected && <p> {`Selected ${selectedRowKeys.length} student(s)`}</p>}
      <br />
      <p>Select Student(s)</p>

      {maillist && (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          size="small"
          pagination={false}
          dataSource={maillist}
          bordered
          rowKey="id"
        />
      )}
      <br />

      <Radio.Group
        defaultValue={1}
        buttonStyle="solid"
        value={grup}
        onChange={grupChanged}
      >
        <Radio.Button value={1}>Send All</Radio.Button>
        <Radio.Button value={2}>Only Students</Radio.Button>
        <Radio.Button value={3}>Only Parents</Radio.Button>
      </Radio.Group>
      <br />
      <br />
      <p>Type Email Content</p>
      <ReactQuill
        className="!max-w-3xl"
        // modules={modules}
        theme="snow"
        value={quillValue}
        onChange={(e) => quillChange(e)}
        placeholder="Type email content"
      />
      <br />

      <Button onClick={sendEmail} type="primary" icon={<SendOutlined />}>
        Send Email
      </Button>
    </div>
  );
};

export default SendEmail;
