import {
  ArrowRightOutlined,
  GroupOutlined,
  HomeOutlined,
  MailOutlined,
  MenuOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Layout, Row, Col, Button, Drawer, Menu, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Students from "src/components/admin/Students";
import TopicComplete from "src/components/admin/TopicComplete";
import TopicDetails from "src/components/admin/TopicDetails";
import ContactForm from "src/components/home/ContactForm";
import ResultResourceDetails from "src/components/home/ResultResourceDetails";
import ResultTopicDetails from "src/components/home/ResultTopicDetails";
import SearchResult from "src/components/home/SearchResults";
import StudentsPage from "src/components/home/StudentsPage";
import { GlobalContext } from "src/context/GlobalContext";
import HomePage from "../components/home/HomePage";
import AirFooter from "./AirFooter";

const HomeLayout = () => {
  const { Header, Content } = Layout;
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const router = useHistory();
  const { logo, user } = useContext(GlobalContext);
  const menuClick = (e) => {
    router.push("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  const MainMenu = () => (
    <Menu
      className="main-menum"
      mode={lg ? "horizontal" : "inline"}
      defaultSelectedKeys={actives}
      onClick={menuClick}
    >
      <Menu.Item key="homepage" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="students" icon={<UsergroupAddOutlined />}>
        Students
      </Menu.Item>

      <Menu.Item key="contact" icon={<MailOutlined />}>
        Contact
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className="layout overflow-hidden">
      <Header
        style={{
          position: lg ? "fixed" : "unset",
          zIndex: 111,
          width: "100%",
        }}
      >
        <div className="bg-gradient-to-b from-white to-ebebeb -mx-4 border-b border-gray-300">
          <div className="container mx-auto">
            <Row justify="space-between" align="middle" className="mx-4">
              <Col span={12}>
                <img src={logo} width="200" alt="logo" />
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Space>
                  {lg && (
                    <>
                      <Button>{user.fullname}</Button>
                      <Button
                        type="primary"
                        icon={<ArrowRightOutlined />}
                        onClick={() => router.push("/admin")}
                      >
                        Go Admin
                      </Button>
                      {/* <Button
                      type="primary"
                      size="large"
                      icon={<ScheduleOutlined />}
                      onClick={() => router.push("/home/projects")}
                    >
                      Donate Regularly
                    </Button> */}
                    </>
                  )}
                </Space>

                {!lg && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    icon={<MenuOutlined />}
                    size="large"
                    onClick={() => setIsCollapsed(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
          {lg && (
            <Row>
              <Col span={24}>
                <MainMenu />
              </Col>
            </Row>
          )}
        </div>
      </Header>
      <Content
        style={{
          padding: "0",
          marginTop: lg ? "115px" : "unset",
        }}
      >
        <Drawer
          title={
            <img
              src={require("../assets/images/logo.png").default}
              width="150"
              alt="logo"
            />
          }
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu />
        </Drawer>
        <div className="site-layout-content">
          <Switch>
            <Route path="/home" exact>
              <Redirect to="/home/homepage" />
            </Route>
            <Route path="/home/homepage" component={HomePage} />
            <Route path="/home/contact" component={ContactForm} />
            <Route path="/home/students" component={StudentsPage} />
            <Route
              path="/home/search/topic/:id/details"
              component={ResultTopicDetails}
            />
            <Route
              path="/home/search/resource/:id/details"
              component={ResultResourceDetails}
            />
            <Route path="/home/search" component={SearchResult} />
            <Route
              path="/home/week/:wid/topic/:id/details"
              component={TopicDetails}
            />
            <Route
              path="/home/week/:wid/topic/:id/complete"
              component={TopicComplete}
            />
            <Route>
              <Redirect to="/home" />
            </Route>
          </Switch>
        </div>
      </Content>
      <div style={{ textAlign: "center" }}>
        <AirFooter />
      </div>
    </Layout>
  );
};

export default HomeLayout;
