import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  notification,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Contacts = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [contacts, setContacts] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getContacts().then((data) => {
        console.log(data.data);
        setContacts(data.data);
      });
    }
  }, [initialRender]);
  const deleteContact = (id) => {
    AdminService.deleteContact(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Contact message deleted!",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY ddd HH:mm"),
    },
    {
      title: "Name",
      dataIndex: "sender",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "mail",
    },
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Delete",
      render: (item) => (
        <Tooltip title="Delete Message" placement="left">
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteContact(item.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <Divider orientation="left">Contact Messages</Divider>
      {contacts && (
        <Table
          loading={contacts === null}
          dataSource={contacts}
          columns={columns}
          bordered
          pagination={false}
        />
      )}
    </div>
  );
};

export default Contacts;
