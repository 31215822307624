import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const NewAlert = ({ setNewPanel, setInitialRender }) => {
  const [form] = Form.useForm();
  const [selectedTip, setSelectedTip] = useState("info");
  const onFinish = (v) => {
    console.log(v);
    AdminService.newAlert(v).then((data) => {
      notification.success({
        message: "Success",
        description: "Alert added successfully!",
      });
      onReset();
      setNewPanel(false);
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const tipChange = (v) => {
    console.log(v);
    setSelectedTip(v);
  };
  return (
    <div>
      <Divider orientation="left">Set New Alert</Divider>
      <Form
        initialValues={{ tip: "info" }}
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        {...GlobalVariables.formLayout}
      >
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Message required" }]}
        >
          <Input placeholder="Type alert message" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Description required" }]}
        >
          <Input placeholder="Type alert description" />
        </Form.Item>
        <Form.Item label="Type" name="tip">
          <Select value={selectedTip} onChange={tipChange} size="large">
            <Select.Option key="Info" value="info">
              <Alert type="info" message="Info" className="p-2 m-0" />
            </Select.Option>
            <Select.Option key="Success" value="success">
              <Alert type="success" message="Success" />
            </Select.Option>
            <Select.Option key="Warning" value="warning">
              <Alert type="warning" message="Warning" />
            </Select.Option>
            <Select.Option key="Error" value="error">
              <Alert type="error" message="Error" />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              Save
            </Button>
            <Button htmlType="reset" icon={<ClearOutlined />}>
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewAlert;
