import { SaveOutlined, ClearOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, notification, Space } from "antd";
import React, { useContext } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";

const ContactForm = () => {
  const [form] = Form.useForm();
  const { user } = useContext(GlobalContext);
  const onFinish = (v) => {
    console.log(v);
    AdminService.newContact(v).then((data) => {
      notification.success({
        message: "Success",
        description: "Your message has been sent!",
      });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="mx-auto max-w-4xl">
      <Divider orientation="left">New Contact Form</Divider>
      {user && (
        <div className="m-2 p-4 max-w-4xl bg-f5f5f5 border border-gray-300 shadow-lg">
          <Form
            initialValues={{ fullname: user.fullname, senderid: user.id }}
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
          >
            <Form.Item label="Fullname" name="fullname">
              <Input disabled />
            </Form.Item>
            <Form.Item hidden label="id" name="senderid">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Subject required" }]}
            >
              <Input placeholder="Type your subject" />
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Message required" }]}
            >
              <Input.TextArea placeholder="Type your message" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SendOutlined />}
                >
                  Send
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
