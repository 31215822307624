import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  List,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const TopicAssignments = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [grades, setGrades] = useState(null);
  const [terms, setTerms] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [termValue, setTermValue] = useState(null);
  const [weeks, setWeeks] = useState(null);
  const [topics, setTopics] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getGrades().then((data) => {
        console.log(data.data);
        setGrades(data.data.grades);
      });
    }
  }, [initialRender]);
  const gradeChanged = (g) => {
    console.log(g);
    setSelectedGrade(g);
    AdminService.getTermsWithTopic(g).then((data) => {
      setTerms(data.data);
    });
    AdminService.getTopics(g).then((data) => {
      console.log(data.data);
      setTopics(data.data);
    });

    setSelectedTerm(null);
    setTermValue(null);
    setWeeks(null);
  };
  const termChanged = (id) => {
    setTermValue(id);
    console.log(terms.find((t) => t.id === id));
    setSelectedTerm(terms.find((t) => t.id === id));
    setWeeks(
      terms.find((t) => t.id === id).weeks
        ? terms.find((t) => t.id === id).weeks
        : []
    );
  };
  const topicSelected = (t) => {
    console.log("topic", t);
    console.log("week", selectedWeek);
    AdminService.newAssignment({ topicId: t, weekId: selectedWeek }).then(
      (data) => {
        notification.success({
          message: "Success",
          description: "Topic Added Successfully",
        });
        setVisible(false);
        AdminService.getTermsWithTopic(selectedGrade).then((data) => {
          setTerms(data.data);
          setWeeks(data.data.find((t) => t.id === selectedTerm.id).weeks);
        });
      }
    );
  };
  const addTopic = (item) => {
    console.log(item);
    setSelectedWeek(item.id);
    setVisible(true);
  };
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      render: (no) => "Week-" + no,
    },
    {
      title: "Start Date",
      dataIndex: "start",
      render: (start) => moment(start).format("DD-MM-YY ddd"),
    },
    {
      title: "End Date",
      dataIndex: "end",
      render: (end) => moment(end).format("DD-MM-YY ddd"),
    },
    {
      title: "Topic",

      render: (item) =>
        item.topic !== null ? (
          <Row justify="space-between">
            <span>{item.topic.name}</span>
            <Space>
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => deleteAssignment(item.id, item.topic.id)}
                okText="Yes! Delete."
                cancelText="Cancel"
              >
                <Button
                  type="primary"
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>

              <Button type="primary" size="small" icon={<SearchOutlined />} />
            </Space>
          </Row>
        ) : (
          <Button
            size="small"
            icon={<PlusOutlined />}
            onClick={() => addTopic(item)}
          >
            Add Topic
          </Button>
        ),
    },
  ];
  const deleteAssignment = (wid, tid) => {
    console.log(wid);
    console.log(tid);
    AdminService.deleteAssignment({ weekId: wid, topicId: tid }).then(
      (data) => {
        notification.success({
          message: "Success!",
          description: "Assignment deleted successfully",
        });
        AdminService.getTermsWithTopic(selectedGrade).then((data) => {
          setTerms(data.data);
          setWeeks(data.data.find((t) => t.id === selectedTerm.id).weeks);
        });
      }
    );
  };
  return (
    <div>
      <Drawer
        extra={<Button onClick={() => setVisible(false)}>Cancel</Button>}
        visible={visible}
        title="Select Topic"
        onClose={() => setVisible(false)}
      >
        {topics && (
          <List
            size="small"
            className="p-1"
            bordered
            itemLayout="horizontal"
            dataSource={topics}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Button
                      type="link"
                      onClick={() => topicSelected(item.id)}
                      className="-m-3"
                    >
                      {item.name}
                    </Button>
                  }
                  description={item.description}
                />
              </List.Item>
            )}
          />
        )}
      </Drawer>
      <div className="p-4 bg-gray-50 border border-gray-300 max-w-3xl">
        <Divider orientation="left">Topic Assignments</Divider>
        {grades && (
          <Select
            className="w-60 mb-1"
            allowClear
            placeholder="Please select group"
            onChange={gradeChanged}
          >
            {grades.map((grade) => (
              <Select.Option value={grade.id} key={grade.key}>
                {grade.name}
              </Select.Option>
            ))}
          </Select>
        )}
        <br />
        {terms && (
          <Select
            className="w-60 mb-1"
            allowClear
            placeholder="Please select term"
            onChange={termChanged}
            value={termValue}
          >
            {terms.map((term) => (
              <Select.Option value={term.id} key={term.key}>
                {term.name} ({moment(term.start).format("DD MMM YY")} /{" "}
                {moment(term.end).format("DD MMM YY")})
              </Select.Option>
            ))}
          </Select>
        )}
        <br />
        {weeks && (
          <div>
            <br />

            <Divider orientation="left">Planned Weeks</Divider>
            <Table
              dataSource={weeks}
              size="small"
              bordered
              columns={columns}
              pagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicAssignments;
