import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  notification,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { data } from "autoprefixer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import NewAlert from "./NewAlert";

const Alerts = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [newPanel, setNewPanel] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getAlerts().then((data) => {
        console.log(data.data);
        setAlerts(data.data);
      });
    }
  }, [initialRender]);

  const deleteAlert = (id) => {
    console.log(id);
    AdminService.deleteAlert(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Alert deleted!",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Type",
      dataIndex: "tip",
      render: (tip) => <Alert type={tip} message={tip} />,
    },
    {
      title: "Delete",
      render: (item) => (
        <Tooltip title="Delete Alert" placement="left">
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteAlert(item.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <Button
        type="primary"
        className="float-right m-2"
        onClick={() => setNewPanel(!newPanel)}
        danger={newPanel}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New Alert"}
      </Button>
      {!newPanel && (
        <div className="p-4 bg-gray-50 border border-gray-300 max-w-5xl">
          <Divider orientation="left">Saved Alerts</Divider>

          <Table
            columns={columns}
            bordered
            size="small"
            dataSource={alerts}
            loading={alerts === null}
          />
        </div>
      )}
      {newPanel && (
        <NewAlert
          setNewPanel={setNewPanel}
          setInitialRender={setInitialRender}
        />
      )}
    </div>
  );
};

export default Alerts;
