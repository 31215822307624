import {
  ArrowLeftOutlined,
  FileOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Divider, Button, PageHeader, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams, useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const ResultResourceDetails = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [pane, setPane] = useState(null);
  const { id } = useParams();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getResource(id).then((data) => {
        console.log(data.data);
        setPane(data.data);
      });
    }
  }, [initialRender]);

  const download = (url) => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_FILES_BASEURL + url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    pane && (
      <div className="mx-auto max-w-4xl ">
        <PageHeader
          backIcon={
            <Button size="small" icon={<ArrowLeftOutlined />}>
              Back
            </Button>
          }
          className="site-page-header-responsive"
          onBack={() => router.goBack()}
          title={pane.docType || ""}
        />
        <br />
        <div className="bg-f5f5f5 m-2 p-4 border border-gray-300">
          <div className="border border-gray-300 bg-white p-2">
            <p className="text-base text-mavi"> {pane.title || ""}</p>
            <p className="text-gray-600 mt-1">{pane.description || ""}</p>
            <br />
            <div
              className="text-base"
              dangerouslySetInnerHTML={GlobalVariables.createMarkup(
                pane.details
              )}
            />
            <br />
          </div>

          {pane.files.length > 0 && (
            <div>
              <Divider orientation="left">Resource Files</Divider>
              {pane.files.map((f) => (
                <Button
                  onClick={() => download(f.url)}
                  className="text-left mb-2 "
                  size="large"
                  block
                  key={f.id}
                  icon={<FileOutlined />}
                >
                  {f.name}
                </Button>
              ))}
            </div>
          )}
          <br />
          {pane.links.length > 0 && (
            <div>
              <Divider orientation="left">Resource External Links</Divider>
              {pane.links.map((f) => (
                <Button
                  onClick={() => window.open(f.url, "_blank")}
                  className="text-left mb-2"
                  size="large"
                  block
                  key={f.id}
                  icon={<LinkOutlined />}
                >
                  {f.title}
                </Button>
              ))}
            </div>
          )}
          <br />
          {pane.videos.length > 0 && (
            <div>
              <Divider orientation="left">Resource Videos</Divider>
              {pane.videos.map((f) => (
                <div key={f.id}>
                  <p>{f.title}</p>
                  <div className="player-wrapper">
                    <ReactPlayer
                      url={f.url}
                      className="react-player"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <br />
        <br />
      </div>
    )
  );
};

export default ResultResourceDetails;
