import {
  CloseOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import NewTermForm from "./NewTermForm";

const Terms = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [newPanel, setNewPanel] = useState(false);
  const [terms, setTerms] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getTerms().then((data) => {
        console.log(data.data);
        setTerms(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Start Date",
      dataIndex: "start",
      render: (start) => moment(start).format("DD-MM-YY ddd"),
    },
    {
      title: "End Date",
      dataIndex: "end",
      render: (end) => moment(end).format("DD-MM-YY ddd"),
    },
    {
      title: "Weeks",
      dataIndex: "weeks",
      render: (weeks) => weeks.length,
    },
    {
      title: "Settings",
      render: (item) => (
        <Button
          type="primary"
          size="small"
          onClick={() =>
            router.push("/admin/terms/" + item.id + "/settings", { item })
          }
          icon={<SettingOutlined />}
        />
      ),
    },
  ];
  return (
    <div className="max-w-2xl">
      <Button
        type="primary"
        className="float-right m-2"
        onClick={() => setNewPanel(!newPanel)}
        danger={newPanel}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New Term"}
      </Button>
      {!newPanel && (
        <div className="p-4 bg-gray-50 border border-gray-300 max-w-2xl">
          <Divider orientation="left">Saved Terms</Divider>

          <Table
            columns={columns}
            bordered
            size="small"
            dataSource={terms}
            loading={terms === null}
          />
        </div>
      )}
      {newPanel && (
        <NewTermForm
          setNewPanel={setNewPanel}
          setInitialRender={setInitialRender}
        />
      )}
    </div>
  );
};

export default Terms;
