import { SaveOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const ChangeMentor = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [grades, setGrades] = useState(null);
  const [grade, setGrade] = useState(null);
  const [mentors, setMentors] = useState(null);
  const [students, setStudents] = useState(null);
  const [changes, setChanges] = useState([]);
  const [test, setTest] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getGrades().then((data) => {
        console.log(data.data);
        setGrades(data.data.grades);
      });
    }
  }, [initialRender]);
  const gradeChanged = (v) => {
    setGrade(v);
    setMentors(null);
    setStudents(null);
    AdminService.getGradeStudents(v).then((data) => {
      console.log(data.data);
      setStudents(data.data.students);
      setMentors(data.data.mentors);
    });
  };
  const mentorChanged = (newMentorId, studentId) => {
    console.log("bbb", newMentorId, studentId);
    const c = { newMentorId, studentId };
    setChanges([...changes, c]);
  };

  const columns = [
    {
      title: "Fullname",
      dataIndex: "fullname",
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
    },
    {
      title: "Change",
      render: (item, index) => (
        <Select
          value={test[index]}
          className="w-60"
          placeholder="Select New Mentor"
          onChange={(id) => mentorChanged(id, item.id)}
        >
          {mentors.map((mentor) => (
            <Select.Option value={mentor.id} key={mentor.id}>
              {mentor.fullname}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];
  const saveChanges = () => {
    console.log(changes);
    AdminService.changeMentors(changes).then((data) => {
      console.log(data.data);
      AdminService.getGradeStudents(grade).then((data) => {
        console.log(data.data);
        setStudents(data.data.students);
        setMentors(data.data.mentors);
        setTest([]);
      });
    });
  };
  return (
    <div className="p-4 bg-fafafa border border-gray-300 max-w-3xl">
      {grades && (
        <Select
          className="w-80"
          onChange={gradeChanged}
          value={grade}
          placeholder="Please select Group"
        >
          {grades.map((g) => (
            <Select.Option key={g.id} value={g.id}>
              {g.name}
            </Select.Option>
          ))}
        </Select>
      )}
      {students && mentors && (
        <>
          <Divider orientation="left">Student List</Divider>
          <Table
            className="my-4 border border-gray-300"
            pagination={{ pageSize: 100, hideOnSinglePage: true }}
            dataSource={students}
            columns={columns}
            size="small"
            bordered
          />
          <Button onClick={saveChanges} type="primary" icon={<SaveOutlined />}>
            Save
          </Button>
        </>
      )}
    </div>
  );
};

export default ChangeMentor;
