import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import NewCompany from "./NewCompany";

const Companies = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [newPanel, setNewPanel] = useState(false);
  const [companies, setCompanies] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getCompanies().then((data) => {
        console.log("companies:", data.data);
        setCompanies(data.data);
      });
    }
  }, [initialRender]);

  const deleteCompany = (id) => {
    AdminService.deleteCompany(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Company deleted!",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Logo",
      dataIndex: "item",
      render: (item) => (
        <img
          src={process.env.REACT_APP_FILES_BASEURL + item.logo}
          alt="logo"
          className="h-12"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "item",
      render: (item) => item.name,
    },
    {
      title: "Admin",
      dataIndex: "admin",
      render: (admin) => admin.fullname,
    },
    {
      title: "Admin e-mail",
      dataIndex: "admin",
      render: (admin) => admin.email,
    },
    {
      title: "Admin Phone",
      dataIndex: "admin",
      render: (admin) => admin.phone,
    },
    {
      title: "Actions",
      render: (data) => (
        <Space>
          <Tooltip title="Company Details" placement="left">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() =>
                router.push(`/admin/companies/${data.item.id}/details`)
              }
            />
          </Tooltip>
          <Tooltip title="Edit Company" placement="left">
            <Button
              type="default"
              icon={<EditOutlined />}
              onClick={() =>
                router.push(`/admin/companies/${data.item.id}/edit`)
              }
            />
          </Tooltip>
          <Tooltip title="Delete Company" placement="left">
            <Popconfirm
              title="Are you sure ? All company data will be deleted!"
              onConfirm={() => deleteCompany(data.item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={newPanel ? "Add New Company" : "Companies List"}
        extra={[
          <Button
            key="a"
            onClick={() => setNewPanel(!newPanel)}
            icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
            type="primary"
            danger={newPanel}
          >
            {newPanel ? "Cancel" : "Add Company"}
          </Button>,
        ]}
      />
      {!newPanel && (
        <Table
          scroll={{ x: 600 }}
          loading={companies === null}
          dataSource={companies}
          className="border border-gray-300"
          columns={columns}
          size="small"
        />
      )}
      {newPanel && <NewCompany sir={setInitialRender} newPanel={setNewPanel} />}
    </div>
  );
};

export default Companies;
