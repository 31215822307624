import { Alert, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import MentorAcademic from "./MentorAcademic";

const HomePage = () => {
  const router = useHistory();
  const [initialRender, setInitialRender] = useState(true);
  const [alerts, setAlerts] = useState(null);
  const { showAlerts, setShowAlerts } = useContext(GlobalContext);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getAlerts().then((data) => {
        console.log(data.data);
        setAlerts(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div className="mb-6">
      <div className="container mx-auto mt-2 ">
        {alerts &&
          showAlerts &&
          alerts.length > 0 &&
          alerts.map((alert, index) => (
            <Alert
              onClose={() => setShowAlerts(false)}
              key={index}
              message={alert.message}
              description={alert.description}
              type={alert.tip}
              showIcon
              closable
              className={alerts.length !== index + 1 ? "mb-2" : ""}
            />
          ))}
      </div>
      <div className="container mx-auto border shadow-lg border-gray-300 my-2 ">
        <MentorAcademic />
      </div>
    </div>
  );
};

export default HomePage;
