import { ArrowRightOutlined } from "@ant-design/icons";
import { PageHeader, Col, Row, Card, Divider } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";

import React from "react";
import { useHistory } from "react-router";

const Academic = ({ item, page }) => {
  const router = useHistory();
  const { sm } = useBreakpoint();

  const cardSelected = (id, wid) => {
    console.log("topic id:", id);
    page === "admin" &&
      router.push("/admin/week/" + wid + "/topic/" + id + "/details");
    page === "home" &&
      router.push("/home/week/" + wid + "/topic/" + id + "/details");
  };
  return (
    <div>
      <div className="mt-4 w-full  flex justify-center">
        <img
          src={require("../../assets/images/academic.png").default}
          alt=""
          className="h-8"
        />
      </div>
      <PageHeader
        className="site-page-header-responsive"
        onBack={null}
        title={`Active Term : ${item.name}`}
        subTitle={
          sm
            ? moment(item.start).format("DD-MM-YY") +
              " / " +
              moment(item.end).format("DD-MM-YY")
            : null
        }
      />
      <div className="px-2 lg:px-5 pb-5 border-gray-300 border-b border-t bg-f5f5f5">
        <Divider orientation="left">
          Academic program of this term {!sm && <br />}
          <span className="text-red-600">
            (Click to card for details and resources)
          </span>
        </Divider>
        <Row gutter={[24, 24]}>
          {item.weeks.map((week) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={week.no}>
              <div className="relative h-full">
                <Card
                  onClick={() =>
                    week.topic ? cardSelected(week.topic.id, week.id) : null
                  }
                  hoverable
                  extra={
                    <span className="text-emerald-600">
                      {moment(week.start).format("DD MMM") +
                        " - " +
                        moment(week.end).format("DD MMM")}
                    </span>
                  }
                  type="inner"
                  title={`Week-${week.no}`}
                  className={
                    moment().isBetween(moment(week.start), moment(week.end))
                      ? " h-full border-2 border-emerald-500 shadow-xl hover:border-emerald-300"
                      : "h-full border border-gray-300 "
                  }
                >
                  <Card.Meta
                    title={
                      <span className="text-blue-500 font-normal text-base">
                        {week.topic
                          ? "Topic: " + week.topic.name
                          : "No assignment yet"}
                      </span>
                    }
                    description={
                      <p>
                        {week.topic ? (
                          <>
                            <ArrowRightOutlined /> {week.topic.description}
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    }
                  />
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Academic;
