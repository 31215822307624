import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import NewTopic from "./NewTopic";

const Topics = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [topics, setTopics] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const [grades, setGrades] = useState(null);
  const [docTypes, setDocTypes] = useState([]);
  const searchInput = useRef();
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);

      AdminService.getGrades().then((data) => {
        console.log(data.data);
        setGrades(data.data.grades);
        setDocTypes(data.data.docTypes);
      });
    }
  }, [initialRender]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "Group",
      dataIndex: "grade",
    },
    {
      title: "Topic",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Resources",
      dataIndex: "resources",
    },
    {
      title: "Action",
      render: (item) => (
        <Tooltip title="Get Details" placement="left">
          <Button
            size="small"
            icon={<SearchOutlined />}
            type="primary"
            onClick={() => getDetails(item)}
          />
        </Tooltip>
      ),
    },
  ];

  const getDetails = (item) => {
    console.log(item);
  };
  const gradeChanged = (v) => {
    if (v === undefined) {
      setTopics(null);
      return;
    }
    console.log(v);
    AdminService.getTopics(v).then((data) => {
      console.log(data.data);
      setTopics(data.data);
    });
  };

  return (
    <div className="max-w-3xl">
      <Button
        type="primary"
        className="float-right m-2"
        onClick={() => setNewPanel(!newPanel)}
        danger={newPanel}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New Topic"}
      </Button>
      {!newPanel && (
        <div className="p-4 bg-gray-50 border border-gray-300 max-w-3xl">
          <Divider orientation="left">Saved Topics</Divider>
          {grades && (
            <Select
              allowClear
              placeholder="Please select group"
              onChange={gradeChanged}
            >
              {grades.map((grade) => (
                <Select.Option value={grade.id} key={grade.key}>
                  {grade.name}
                </Select.Option>
              ))}
            </Select>
          )}
          {
            <Table
              columns={columns}
              bordered
              size="small"
              dataSource={topics}
            />
          }
        </div>
      )}
      {newPanel && (
        <NewTopic
          setNewPanel={setNewPanel}
          sir={setInitialRender}
          grades={grades}
          docTypes={docTypes}
        />
      )}
    </div>
  );
};

export default Topics;
