import {
  SaveOutlined,
  ClearOutlined,
  PlusOutlined,
  ArrowRightOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Tag,
  Upload,
} from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const NewTopic = ({ sir, setNewPanel, grades, docTypes }) => {
  // const [grades, setGrades] = useState(null);
  // const [docTypes, setDocTypes] = useState([]);
  const [quillValue, setQuillValue] = useState("");
  const [quillValue2, setQuillValue2] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedResources, setSelectedResources] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [resources, setResources] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const onFinish = (v) => {
    console.log(v);
    var req = {
      gradeId: v.grade,
      name: v.name,
      description: v.description,
      details: quillValue,
      resources,
    };
    console.log(req);
    AdminService.newTopic(req).then((data) => {
      console.log("donen", data.data);
      notification.success({
        message: "Success!",
        description: "New Topic Added Successfully.",
      });
      onReset();
    });
  };
  const resourceCompleted = (v) => {
    console.log(v);
    console.log(fileList);
    const files =
      fileList.length > 0
        ? fileList.map((f) => {
            return {
              type: f.type,
              size: f.size,
              name: f.name,
              url: f.response.url,
              isCover: false,
            };
          })
        : [];
    const cover =
      fileList2.length > 0
        ? {
            isCover: true,
            type: fileList2[0].type,
            size: fileList2[0].size,
            name: fileList2[0].name,
            url: fileList2[0].response.url,
          }
        : null;

    if (cover !== null) {
      files.push(cover);
    }

    const req = {
      ...v,
      docTypeId: selectedResources.id,
      docType: selectedResources.name,
      files: files.length > 0 ? files : null,
      details: quillValue2,
    };
    console.log("req:", req);
    setResources([...resources, req]);
    resourceReset();
    setVisible(false);
    notification.success({
      message: "Success!",
      description: "Resources Added Successfully.",
    });
  };
  const onReset = () => {
    form.resetFields();
    setResources([]);
    setQuillValue("");
  };
  const resourceReset = () => {
    form2.resetFields();
    setFileList([]);
    setFileList2([]);
    setQuillValue2("");
    setSelectedResources(null);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  const quillChange2 = (e) => {
    setQuillValue2(e);
  };
  const onMenuChange = (v) => {
    console.log(v);
    setSelectedResources(v);
    setVisible(true);
  };
  const menu = (
    <Menu>
      {docTypes.map((d) => (
        <Menu.Item onClick={() => onMenuChange(d)} key={d.id}>
          <ArrowRightOutlined /> {d.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const onClose = () => {
    setVisible(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange2 = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await GlobalVariables.getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const isVisible = (arr = []) => {
    return arr.findIndex((a) => a === selectedResources.id) === -1
      ? false
      : true;
  };
  const deleteResource = (index) => {
    console.log(index);
    setResources(
      resources.filter((d) => {
        return d.title !== index;
      })
    );
  };
  return (
    <div className="max-w-3xl bg-gray-50 p-6 border border-gray-300 rounded-md ">
      {selectedResources && (
        <Drawer
          title={`Add ${selectedResources.name}`}
          placement="right"
          width={500}
          onClose={onClose}
          visible={visible}
          extra={
            <Space>
              <Button danger onClick={onClose}>
                Cancel
              </Button>
            </Space>
          }
        >
          <div className="bg-gray-50 h-full p-2">
            <Form
              form={form2}
              onFinish={resourceCompleted}
              onReset={resourceReset}
              layout="vertical"
              initialValues={{ links: null, videos: null, gender: 3 }}
            >
              <Form.Item
                name="title"
                label="Resource Title"
                rules={[{ required: true, message: "Title required" }]}
              >
                <Input placeholder="Type resource title" autoComplete="off" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Short Description about this resource"
              >
                <Input.TextArea
                  placeholder="Type resource description (optional)"
                  autoComplete="off"
                />
              </Form.Item>
              {isVisible([4, 7]) && (
                <Form.Item
                  label="Details"
                  rules={[{ required: true, message: "Details required" }]}
                >
                  <ReactQuill
                    modules={modules}
                    theme="snow"
                    onChange={(e) => quillChange2(e)}
                    value={quillValue2}
                    placeholder="Type resource long details (optional)"
                  />
                </Form.Item>
              )}
              {isVisible([1, 2, 7]) && (
                <Form.Item
                  label={`Add ${selectedResources.name.toLowerCase()} files`}
                >
                  <>
                    <Upload
                      action={process.env.REACT_APP_API_BASEURL + "upload"}
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      }}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </>
                </Form.Item>
              )}
              {isVisible([2]) && (
                <Form.Item
                  label={`Add ${selectedResources.name.toLowerCase()} cover image`}
                >
                  <>
                    <Upload
                      action={process.env.REACT_APP_API_BASEURL + "upload"}
                      headers={{
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      }}
                      listType="picture-card"
                      fileList={fileList2}
                      onPreview={handlePreview}
                      onChange={handleChange2}
                    >
                      {fileList2.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </>
                </Form.Item>
              )}
              <Form.List name="links">
                {(fields, { add, remove }) => (
                  <>
                    <p>{selectedResources.name + " external links"}</p>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          fieldKey={[fieldKey, "title"]}
                          rules={[{ required: true, message: "Missing title" }]}
                        >
                          <Input placeholder="Type title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          fieldKey={[fieldKey, "url"]}
                          rules={[{ required: true, message: "Missing url" }]}
                        >
                          <Input placeholder="Paste link here" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {"Add " +
                          selectedResources.name.toLowerCase() +
                          " external link"}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.List name="videos">
                {(fields, { add, remove }) => (
                  <>
                    <p>{selectedResources.name + " video links"}</p>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          fieldKey={[fieldKey, "title"]}
                          rules={[{ required: true, message: "Missing title" }]}
                        >
                          <Input placeholder="Type title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "url"]}
                          fieldKey={[fieldKey, "url"]}
                          rules={[{ required: true, message: "Missing url" }]}
                        >
                          <Input placeholder="Paste video link here" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {"Add " +
                          selectedResources.name.toLowerCase() +
                          " Youtube/Vimeo link"}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item label="Gender" name="gender">
                <Select>
                  <Select.Option key="3" value={3}>
                    For All Students
                  </Select.Option>
                  <Select.Option key="2" value={2}>
                    Only Girls
                  </Select.Option>
                  <Select.Option key="1" value={1}>
                    Only Boys
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                  <Button htmlType="reset" icon={<ClearOutlined />}>
                    Clear
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      )}
      <Divider orientation="left">Add New Weekly Topic</Divider>

      {grades && (
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          {...GlobalVariables.formLayout}
        >
          <Form.Item
            name="grade"
            label="Select Group"
            rules={[{ required: true, message: "Group required" }]}
          >
            <Select allowClear placeholder="Please select group">
              {grades.map((grade) => (
                <Select.Option value={grade.id} key={grade.key}>
                  {grade.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Topic"
            name="name"
            rules={[{ required: true, message: "Topic required" }]}
          >
            <Input placeholder="Type topic" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Short Description"
            name="description"
            rules={[{ required: true, message: "Description required" }]}
          >
            <Input placeholder="Type short description" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Details"
            rules={[{ required: true, message: "Details required" }]}
          >
            <ReactQuill
              modules={modules}
              theme="snow"
              value={quillValue}
              onChange={(e) => quillChange(e)}
              placeholder="Type topic long details (optional)"
            />
          </Form.Item>
          <Form.Item label="Resources">
            {resources.length > 0 &&
              resources.map((r) => (
                <div className="mb-1" key={r.title}>
                  <Tag color="blue">{r.docType}</Tag>
                  <span> {r.title} added</span>
                  <Popconfirm
                    title="Are you sure ?"
                    onConfirm={() => deleteResource(r.title)}
                    okText="Yes! Delete."
                    cancelText="Cancel"
                  >
                    <Button
                      type="primary"
                      danger
                      size="small"
                      icon={<DeleteOutlined />}
                      className="ml-2"
                    />
                  </Popconfirm>
                </div>
              ))}
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button icon={<PlusOutlined />}>Add Resources</Button>
            </Dropdown>
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default NewTopic;
