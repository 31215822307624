import {
  ArrowRightOutlined,
  DeleteOutlined,
  MailOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Row,
  Select,
  Tag,
  Comment,
  Tooltip,
  Progress,
  Statistic,
  Button,
  Popover,
  Space,
  Popconfirm,
  notification,
} from "antd";
import { data } from "autoprefixer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const WeeklyReports = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [terms, setTerms] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [weeks, setWeeks] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [reports, setReports] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getTerms().then((data) => {
        console.log(data.data);
        setTerms(data.data);
      });
    }
  }, [initialRender]);
  const termChanged = (t) => {
    console.log(t);
    setSelectedTerm(t);
    setWeeks(terms.find((f) => f.id === t).weeks);
  };
  const weekChanged = (w) => {
    console.log(w);
    setSelectedWeek(w);
    AdminService.getWeeklyMentorsReports(w).then((data) => {
      console.log(data.data);
      setReports(data.data);
    });
  };
  const sendMailToParents = (id) => {
    console.log(id);
    AdminService.sendReport(id).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success!",
        description: "Message sent!",
      });
    });
  };
  return (
    <div>
      <Divider orientation="left">Weekly Mentor Reports</Divider>
      {terms && (
        <Select
          value={selectedTerm}
          onChange={termChanged}
          placeholder="Please select term"
          className="w-80 block"
        >
          {terms.map((term) => (
            <Select.Option key={term.id} value={term.id}>
              {term.name}
            </Select.Option>
          ))}
        </Select>
      )}
      {weeks && (
        <Select
          value={selectedWeek}
          onChange={weekChanged}
          placeholder="Please select week"
          className="w-80 block my-2"
        >
          {weeks.map((week) => (
            <Select.Option key={week.id} value={week.id}>
              Week-{week.no} <ArrowRightOutlined />{" "}
              {moment(week.start).format("DD MMM")} -{" "}
              {moment(week.end).format("DD MMM")}
            </Select.Option>
          ))}
        </Select>
      )}
      <div className="bg-fafafa p-4 border border-gray-300 my-6">
        <Row gutter={[16, 16]}>
          {reports &&
            reports.map((r) => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={r.key}>
                <Card
                  title={r.fullname}
                  size="small"
                  className="border-gray-300 h-full"
                  extra={
                    <Space>
                      <Tag color="blue"> {r.grade}</Tag>{" "}
                      {r.report && (
                        <Tooltip title="Send email to parents" placement="left">
                          <Popconfirm
                            title="Are you sure ?"
                            onConfirm={() => sendMailToParents(r.report.id)}
                            okText="Yes! Send."
                            cancelText="Cancel"
                          >
                            <Button
                              type="primary"
                              size="small"
                              icon={<MailOutlined />}
                            >
                              Send Mail
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                      )}
                    </Space>
                  }
                >
                  {r.report && (
                    <div>
                      <p>Topic: {r.report.topic}</p>
                      <Comment
                        author="Message to Coordinator"
                        avatar={<NotificationOutlined />}
                        content={
                          <p>{r.report.cmessage || "No coordinator message"}</p>
                        }
                        datetime={
                          <Tooltip
                            title={moment(r.report.createdOn).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(r.report.createdOn).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                      <Comment
                        author="Message to Students"
                        avatar={<NotificationOutlined />}
                        content={
                          <p>{r.report.smessage || "No students message"}</p>
                        }
                        datetime={
                          <Tooltip
                            title={moment(r.report.createdOn).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(r.report.createdOn).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                      <Comment
                        author="Message to Parents"
                        avatar={<NotificationOutlined />}
                        content={
                          <p>{r.report.pmessage || "No parents message"}</p>
                        }
                        datetime={
                          <Tooltip
                            title={moment(r.report.createdOn).format(
                              "DD-MM-YY ddd hh:MM"
                            )}
                          >
                            <span>{moment(r.report.createdOn).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                      <br />
                      <Row
                        gutter={[16, 16]}
                        justify="space-around"
                        align="middle"
                      >
                        <Col>
                          <Progress
                            type="circle"
                            percent={Math.round(
                              (r.report.students.length / r.students) * 100
                            )}
                            status="normal"
                            width={80}
                          />
                        </Col>
                        <Col>
                          {/* <p>Students Count: {r.students}</p>
                          <p>
                            Number of participants: {r.report.students.length}
                          </p> */}
                          <Statistic
                            title="Participation"
                            value={r.report.students.length}
                            suffix={`/${r.students}`}
                          />
                        </Col>
                        <Col>
                          <Popover
                            content={r.report.students.map((s) => (
                              <p key={s.fullname}>{s.fullname}</p>
                            ))}
                            title="Attendees"
                          >
                            <Button type="default">Attendees</Button>
                          </Popover>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {!r.report && (
                    <p className="text-base text-red-600">Report not found!</p>
                  )}
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default WeeklyReports;
