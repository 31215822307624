import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import NewMentors from "./NewMentor";

const Mentors = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [newPanel, setNewPanel] = useState(false);
  const [mentors, setMentors] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMentors("Mentor").then((data) => {
        console.log(data.data);
        setMentors(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (gender) => (gender === 1 ? "Male" : "Female"),
    },
    {
      title: "Group",
      dataIndex: "grade",
    },
    {
      title: "Students",
      dataIndex: "students",
    },
  ];
  return (
    <div className="max-w-3xl">
      <Button
        type="primary"
        className="float-right m-2"
        onClick={() => setNewPanel(!newPanel)}
        danger={newPanel}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New Mentor"}
      </Button>
      {!newPanel && (
        <div className="p-4 bg-gray-50 border border-gray-300 max-w-3xl">
          <Divider orientation="left">Saved Mentors</Divider>

          <Table
            columns={columns}
            bordered
            size="small"
            dataSource={mentors}
            loading={mentors === null}
          />
        </div>
      )}
      {newPanel && <NewMentors newPanel={setNewPanel} sir={setInitialRender} />}
    </div>
  );
};

export default Mentors;
