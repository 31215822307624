import { ClearOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Grades = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [grades, setGrades] = useState(null);
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    console.log(values);
    AdminService.newGrade(values).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success!",
        description: "Group saved successfully",
      });
      onReset();
      setInitialRender(true);
    });
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getGrades().then((data) => {
        console.log(data.data);
        setGrades(data.data.grades);
      });
    }
  }, [initialRender]);

  const deleteGrade = (id) => {
    console.log(id);
    AdminService.deleteGrade(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Group deleted successfully",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name) => <span>{name}</span>,
    },
    {
      title: "Action",
      render: (item) => (
        <Tooltip title="Delete Group" placement="left" key={item.id}>
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteGrade(item.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div className="max-w-lg">
      <Divider orientation="left">Groups</Divider>
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={grades}
        pagination={false}
      />
      <Divider orientation="left">Add New Group</Divider>
      <Form
        form={form}
        {...GlobalVariables.formLayout}
        onFinish={onFinish}
        onReset={onReset}
      >
        <Form.Item
          label="Group Name"
          name="name"
          rules={[{ required: true, message: "Group name required" }]}
        >
          <Input placeholder="Type group no" autoComplete="off" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              Save
            </Button>
            <Button htmlType="reset" icon={<ClearOutlined />}>
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Grades;
