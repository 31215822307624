import {
  Button,
  Divider,
  Form,
  Input,
  Space,
  Select,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const NewMentors = ({ newPanel, sir }) => {
  const [form] = Form.useForm();
  const [gom, setGom] = useState(null);
  const [gos, setGos] = useState(null);
  const [grades, setGrades] = useState(null);
  const [sgrade, setSgrade] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getGrades().then((data) => {
        setGrades(data.data.grades);
      });
    }
  }, [initialRender]);
  const gomChanged = (g) => {
    console.log(g);
    setGom(g);
  };
  const gosChanged = (g) => {
    console.log(g);
    setGos(g);
  };
  const onFinish = (v) => {
    console.log(v);
    var req = {
      fullname: v.fullname,
      email: v.email,
      password: v.password,
      phone: v.gsm,
      role: "Mentor",
      gender: v.mentorGender,
      sgender: v.studentsGender,
      gradeid: v.gradeid,
    };
    AdminService.newPerson(req).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success",
        description: "New mentor added successfully",
      });
      newPanel(false);
      sir(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
    setGom(null);
    setGos(null);
  };
  const gradeChanged = (g) => {
    setSgrade(g);
  };
  return (
    <div>
      <Form
        form={form}
        {...GlobalVariables.formLayout}
        onFinish={onFinish}
        onReset={onReset}
      >
        <Divider>New Mentor Form</Divider>
        <Form.Item
          label="Mentor Fullname"
          name="fullname"
          rules={[{ required: true, message: "Mentor fullname required" }]}
        >
          <Input placeholder="Please type Mentor fullname" />
        </Form.Item>
        <Form.Item
          label="Mentor email address"
          name="email"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input placeholder="Please type Mentor email" />
        </Form.Item>
        <Form.Item
          label="Mentor mobile phone"
          name="gsm"
          rules={[{ required: true, message: "Mobile phone required" }]}
        >
          <Input placeholder="Please type Mentor phone" />
        </Form.Item>
        <Form.Item
          label="Mentor Group"
          name="gradeid"
          rules={[{ required: true, message: "Group required" }]}
        >
          {grades && (
            <Select
              value={sgrade}
              onChange={gradeChanged}
              placeholder="Please choose group"
            >
              {grades.map((g) => (
                <Select.Option key={g.id} value={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Gender of mentor"
          name="mentorGender"
          rules={[{ required: true, message: "This field required" }]}
        >
          <Select
            onChange={gomChanged}
            value={gom}
            placeholder="Select gender of mentor"
          >
            <Select.Option key="male" value={1}>
              Male
            </Select.Option>
            <Select.Option key="female" value={2}>
              Female
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Gender of students"
          name="studentsGender"
          rules={[{ required: true, message: "This field required" }]}
        >
          <Select
            onChange={gosChanged}
            value={gos}
            placeholder="Select gender of students"
          >
            <Select.Option key="all" value={3}>
              Girls & Boys
            </Select.Option>
            <Select.Option key="female" value={2}>
              Only Girls
            </Select.Option>
            <Select.Option key="male" value={1}>
              Only Boys
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Mentor Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password required!",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
              message:
                "Your password must be 6-25 digits, contain letters and numbers",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Mentor password again"
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords did not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Password again" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button htmlType="reset">Clear</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewMentors;
