import { Col, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import Academic from "../admin/Academic";

const MentorAcademic = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [activeTerm, setActiveTerm] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const { user } = useContext(GlobalContext);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getActivetermtopics(
        JSON.parse(localStorage.getItem("user")).gradeid
      ).then((data) => {
        console.log("aaa", data.data);
        setActiveTerm(data.data);
        setTerms(data.data.terms);
      });
    }
  }, [initialRender]);

  const termChanged = (t) => {
    setSelectedTerm(t);
    AdminService.getTermTopicsById(
      t,
      JSON.parse(localStorage.getItem("user")).gradeid
    ).then((data) => {
      console.log("bbb", data.data);
      setActiveTerm(data.data);
    });
  };
  const onSearch = (text) => {
    console.log(text);
    router.push("/home/search", { text });
  };
  return (
    user &&
    user.role === "Mentor" && (
      <div className="bg-fafafa">
        <Row wrap justify="space-between" align="middle">
          <Col>
            {terms && terms.length > 0 && (
              <Select
                value={selectedTerm}
                onChange={termChanged}
                className="w-80 m-4"
                placeholder="Select Term"
              >
                {terms.map((term) => (
                  <Select.Option key={term.key} value={term.id}>
                    {term.name} ({moment(term.start).format("DD MMM")} -{" "}
                    {moment(term.end).format("DD MMM")})
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col>
            <Input.Search
              className="px-2 w-96 max-w-full"
              placeholder="Search topics and resources "
              onSearch={onSearch}
              enterButton
            />
          </Col>
        </Row>

        {activeTerm && terms.length > 0 && (
          <Academic item={activeTerm} page="home" />
        )}
      </div>
    )
  );
};

export default MentorAcademic;
