import {
  ClearOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PlusOutlined,
  SaveOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import NewStudent from "./NewStudent";

const Students = ({ home = false }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [newPanel, setNewPanel] = useState(false);
  const [mentors, setStudents] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const { user } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);

      if (home) {
        AdminService.getMentorStudents(
          JSON.parse(localStorage.getItem("user")).id,
          true
        ).then((data) => {
          console.log(data.data);
          setStudents(data.data);
        });
      } else {
        AdminService.getStudents("Student").then((data) => {
          console.log(data.data);
          setStudents(data.data);
        });
      }
    }
  }, [home, initialRender, user]);

  const getParents = (item) => {
    console.log(item);
    setSelectedItem(item);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const deleteStudent = (id) => {
    AdminService.deleteStudent(id).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success",
        description: "Student deleted!",
      });
    });
  };
  const editStudent = (item) => {
    console.log(item);
    editForm.setFieldsValue({
      fullname: item.fullname,
      email: item.email,
      phone: item.phone,
      id: item.id,
    });
    setEditModal(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (gender) => (gender === 1 ? "Male" : "Female"),
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
    },
    {
      title: "Parents",
      render: (item) => (
        <Button
          onClick={() => getParents(item)}
          size="small"
          type="primary"
          ghost
          icon={item.parents.length > 0 ? <UserOutlined /> : null}
        >
          {item.parents.length === 0 ? (
            "Add"
          ) : (
            <>&nbsp; {item.parents.length}</>
          )}
        </Button>
      ),
    },
    {
      title: "Actions",
      render: (item) => (
        <>
          <Tooltip title="Delete Student" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteStudent(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Button
            className="!ml-2"
            icon={<EditOutlined />}
            type="primary"
            onClick={() => editStudent(item)}
          />
        </>
      ),
    },
  ];
  const onFinish = (v) => {
    console.log(v);
    const req = { ...v, sid: selectedItem.id };
    AdminService.newParent(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Parent added successfully!",
      });
      onReset();
      setVisible(false);
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const deleteParent = (pid) => {
    console.log(pid);
    AdminService.deleteParent(pid, selectedItem.id).then((data) => {
      notification.success({
        message: "Success",
        description: "Parent deleted from this student!",
      });
      setVisible(false);
      setInitialRender(true);
    });
  };
  const onEditFinish = (v) => {
    console.log(v);
    AdminService.editStudent(v).then((data) => {
      notification.success({
        message: "Success",
        description: "Student Updated!",
      });
      setEditModal(false);
      setInitialRender(true);
    });
  };
  const onEditReset = () => {
    editForm.resetFields();
  };
  return (
    <div className="max-w-4xl mx-auto m-4">
      <Modal
        visible={editModal}
        title="Edit Student Details"
        footer={false}
        onCancel={() => setEditModal(false)}
      >
        <Form
          {...GlobalVariables.formLayout}
          form={editForm}
          size="small"
          onFinish={onEditFinish}
          onReset={onEditReset}
        >
          <Form.Item name="id" className="hidden"></Form.Item>
          <Form.Item
            label="Fullname"
            name="fullname"
            rules={[{ required: true, message: "Student fullname required" }]}
          >
            <Input
              placeholder="Please type student fullname"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "E-mail required" }]}
          >
            <Input
              placeholder="Please type student email"
              autoComplete="off"
              autoCapitalize="off"
            />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Mobile phone required" }]}
          >
            <Input placeholder="Please type student phone" autoComplete="off" />
          </Form.Item>
          <Form.Item {...GlobalVariables.tailLayout}>
            <Space>
              <Button htmlType="reset">Clear</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      {selectedItem && user && (
        <Drawer
          title={`${selectedItem.fullname}  Parents`}
          closable
          visible={visible}
          onClose={onClose}
        >
          <div className="p-3">
            {selectedItem.parents.map((p) => (
              <div key={p.key}>
                <Descriptions
                  title={
                    <div>
                      {p.fullname}

                      <Tooltip title="Delete parents" placement="right">
                        <Popconfirm
                          title="Are you sure ?"
                          onConfirm={() => deleteParent(p.id)}
                          okText="Yes! Delete."
                          okType="danger"
                          cancelText="Cancel"
                        >
                          <DeleteOutlined className="text-red-600 ml-3" />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  }
                  bordered
                  column={1}
                  size="small"
                >
                  <Descriptions.Item label="Phone">{p.phone}</Descriptions.Item>
                  <Descriptions.Item label="E-Mail">
                    {p.email}
                  </Descriptions.Item>
                </Descriptions>
                <Divider />
              </div>
            ))}
            <div className="bg-f5f5f5 px-4 my-4 border border-gray-300">
              <Divider orientation="left">Add New Parent</Divider>
              <Form form={form} onFinish={onFinish} onReset={onReset}>
                <Form.Item
                  name="fullname"
                  rules={[{ required: true, message: "This field required" }]}
                >
                  <Input placeholder="Type parent fullname" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: "This field required" }]}
                >
                  <Input placeholder="Type parent phone" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "This field required" }]}
                >
                  <Input placeholder="Type parent email" />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                    <Button htmlType="reset" icon={<ClearOutlined />}>
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Drawer>
      )}
      <Button
        type="primary"
        className="float-right m-2"
        onClick={() => setNewPanel(!newPanel)}
        danger={newPanel}
        icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
      >
        {newPanel ? "Cancel" : "Add New Student"}
      </Button>
      {!newPanel && (
        <div className="p-4 bg-gray-50 border border-gray-300 max-w-4xl shadow-lg">
          <Divider orientation="left">Saved Students</Divider>

          <Table
            scroll={{ x: 700 }}
            pagination={{ pageSize: 50, hideOnSinglePage: true }}
            columns={columns}
            bordered
            size="small"
            dataSource={mentors}
            loading={mentors === null}
            className="border border-gray-200"
          />
        </div>
      )}
      {newPanel && <NewStudent newPanel={setNewPanel} sir={setInitialRender} />}
    </div>
  );
};

export default Students;
