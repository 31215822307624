import { axios } from "./HttpClient";

export const AdminService = {
  login: (values) => {
    return axios.post("/validation/login", values);
  },
  newCompany: (values) => {
    return axios.post("/company", values);
  },
  getCompanies: () => {
    return axios.get("/company");
  },
  deleteCompany: (id) => {
    return axios.delete("/company/" + id);
  },
  getGrades: () => {
    return axios.get("/grades");
  },
  newGrade: (values) => {
    return axios.post("/grades", values);
  },
  deleteGrade: (id) => {
    return axios.delete("/grades/" + id);
  },
  newTopic: (values) => {
    return axios.post("/topics", values);
  },
  newTerm: (values) => {
    return axios.post("/terms", values);
  },
  getTerms: () => {
    return axios.get("/terms");
  },
  getTopics: (id) => {
    return axios.get("/topics?id=" + id);
  },
  getTermsGrades: () => {
    return axios.get("/terms/grades");
  },
  getTermsWithTopic: (gid) => {
    return axios.get("/terms/withtopics?gradeId=" + gid);
  },
  getActivetermtopics: (gid) => {
    return axios.get("/terms/activetermtopics?gradeId=" + gid);
  },
  getTermTopicsById: (id, gid) => {
    return axios.get("/terms/termtopicsbyid?id=" + id + "&gradeId=" + gid);
  },
  newAssignment: (values) => {
    return axios.post("/assignments", values);
  },
  deleteAssignment: (values) => {
    return axios.post("/assignments/delete", values);
  },
  newPerson: (values) => {
    return axios.post("/people", values);
  },
  getMentors: (role) => {
    return axios.get("/people?role=" + role);
  },
  getStudents: (role) => {
    return axios.get("/people?role=" + role);
  },
  getTopicDetails: (id) => {
    return axios.get("/topics/" + id);
  },
  getMentorStudents: (id, hepsi = false) => {
    return axios.get(`/people/mentor/${id}/students?hepsi=${hepsi}`);
  },
  newReport: (values) => {
    return axios.post("/reports", values);
  },
  getWeeklyMentorsReports: (wid) => {
    return axios.get("/reports/weeklymentors?weekid=" + wid);
  },
  getGradeStudents: (id) => {
    return axios.get("/grades/" + id + "/students");
  },
  changeMentors: (values) => {
    return axios.post("/people/changementors", { changes: values });
  },
  getMyCompany: () => {
    return axios.get("/company/getmycompany");
  },
  getReport: (wid, tid, mid) => {
    return axios.get(`/reports/check?wid=${wid}&tid=${tid}&mid=${mid}`);
  },
  sendReport: (id) => {
    return axios.get("/reports/sendreport?id=" + id);
  },
  getMentorReports: (tid, mid) => {
    return axios.get("/reports/mentorreports?tid=" + tid + "&mid=" + mid);
  },
  getAlerts: () => {
    return axios.get("/alerts");
  },
  newAlert: (values) => {
    return axios.post("/alerts", values);
  },
  deleteAlert: (id) => {
    return axios.delete("/alerts/" + id);
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  newContact: (values) => {
    return axios.post("/contacts", values);
  },
  deleteContact: (id) => {
    return axios.delete("/contacts/" + id);
  },
  getStats: () => {
    return axios.get("/reports/stats");
  },
  newParent: (values) => {
    return axios.post("/parents", values);
  },
  deleteParent: (pid, sid) => {
    return axios.delete("/parents?pid=" + pid + "&sid=" + sid);
  },
  searchText: (text) => {
    return axios.get("/reports/search?text=" + text);
  },
  getResource: (id) => {
    return axios.get("/topics/resource/" + id);
  },
  deleteStudent: (id) => {
    return axios.delete("/people/deletestudent/" + id);
  },
  editStudent: (values) => {
    return axios.put("/people/updateStudent/" + values.id, values);
  },
  sendEmail: (values) => {
    return axios.post("/email/send", values);
  },
};
