import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  notification,
  Row,
  Statistic,
  Table,
} from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import { SaveOutlined } from "@ant-design/icons";
import { AdminService } from "src/resources/AdminService";
const { RangePicker } = DatePicker;
const NewTermForm = ({ setNewPanel, setInitialRender }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [weeks, setWeeks] = useState(null);
  const [name, setName] = useState("");
  const [tarihler, setTarihler] = useState(null);

  const onChange = (date) => {
    console.log(date[0].format(), date[1].format());
    setTarihler(date);
    if (date !== null) {
      setStartDate(moment(date[0]).startOf("week").format());

      setEndDate(moment(date[1]).endOf("week").format());

      const haftalar = [];
      for (let index = 0; index <= date[1].diff(date[0], "week"); index++) {
        const hafta = {
          key: index,
          no: index + 1,
          start: moment(moment(date[0]))
            .add(index, "weeks")
            .startOf("week")
            .format(),
          end: moment(moment(date[0]))
            .add(index, "weeks")
            .endOf("week")
            .format(),
        };
        haftalar.push(hafta);
      }
      setTarihler(date);
      setWeeks(haftalar);
    } else {
      setStartDate(null);
      setEndDate(null);
      setWeeks(null);
      setTarihler(null);
    }
  };
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      render: (no) => "Week-" + no,
    },
    {
      title: "Start Date",
      dataIndex: "start",
      render: (start) => moment(start).format("DD-MM-YY ddd"),
    },
    {
      title: "End Date",
      dataIndex: "end",
      render: (end) => moment(end).format("DD-MM-YY ddd"),
    },
  ];

  const saveTerm = () => {
    console.log(name, startDate, endDate, weeks);
    const req = {
      name,
      start: startDate,
      end: endDate,
      weeks,
    };
    AdminService.newTerm(req).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success",
        description: "Term added seuccessfully",
      });
      setStartDate(null);
      setEndDate(null);
      setName("");
      setWeeks(null);
      setTarihler(null);
      setInitialRender(true);
      setNewPanel(false);
    });
  };
  const nameChanged = (v) => {
    setName(v.target.value);
  };
  return (
    <div className="p-4 bg-gray-50 border border-gray-300 max-w-2xl">
      <Divider orientation="left">New Term Plan</Divider>

      <p>Term Code</p>
      <Input
        placeholder="Type Term code (eg. 2021/4)"
        size="large"
        onChange={nameChanged}
        value={name}
      />
      <br />
      <br />
      <ConfigProvider locale={locale}>
        <p>Term Weeks</p>
        <RangePicker
          picker="week"
          onChange={onChange}
          size="large"
          value={tarihler}
        />
        <Button
          onClick={saveTerm}
          type="primary"
          icon={<SaveOutlined />}
          size="large"
          className="ml-2"
          disabled={name.length < 3 || weeks === null}
        >
          Save Term
        </Button>
      </ConfigProvider>
      {weeks && (
        <div>
          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Card bordered className="border-gray-300">
                <Statistic
                  className="bg-white"
                  title="Start Date"
                  value={moment(startDate).format("DD-MM-YY dddd")}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered className="border-gray-300">
                <Statistic
                  title="End Date"
                  value={moment(endDate).format("DD-MM-YY dddd")}
                />
              </Card>
            </Col>
          </Row>

          <Divider orientation="left">Planned Weeks</Divider>
          <Table dataSource={weeks} size="small" bordered columns={columns} />
        </div>
      )}
    </div>
  );
};

export default NewTermForm;
