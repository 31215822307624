import { Divider } from "antd";
import React from "react";
import Students from "../admin/Students";

const StudentsPage = () => {
  return (
    <div>
      <Students home={true} />
    </div>
  );
};

export default StudentsPage;
