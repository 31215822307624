import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Space,
  Select,
  notification,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const NewStudent = ({ newPanel, sir }) => {
  const [form] = Form.useForm();
  const [gom, setGom] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [grades, setGrades] = useState(null);
  const [sgrade, setSgrade] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getGrades().then((data) => {
        setGrades(data.data.grades);
      });
    }
  }, [initialRender]);

  const gomChanged = (g) => {
    console.log(g);
    setGom(g);
  };

  const onFinish = (v) => {
    console.log(v);
    var req = {
      fullname: v.fullname,
      email: v.email,
      password: v.password,
      phone: v.gsm,
      role: "Student",
      gender: v.gender,
      gradeid: v.gradeid,
      parents: v.parents,
    };
    console.log("req", req);
    AdminService.newPerson(req).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Success",
        description: "New student added successfully",
      });
      newPanel(false);
      sir(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
    setGom(null);
  };
  const gradeChanged = (g) => {
    setSgrade(g);
  };
  return (
    <div className="bg-f5f5f5 p-2">
      <Form
        form={form}
        {...GlobalVariables.formLayout}
        onFinish={onFinish}
        onReset={onReset}
      >
        <Divider>New Student Form</Divider>
        <Form.Item
          label="Student Fullname"
          name="fullname"
          rules={[{ required: true, message: "Student fullname required" }]}
        >
          <Input
            placeholder="Please type student fullname"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          label="Student email address"
          name="email"
          rules={[
            { required: true, message: "E-mail required" },
            { type: "email" },
          ]}
        >
          <Input
            placeholder="Please type student email"
            autoComplete="off"
            autoCapitalize="off"
          />
        </Form.Item>
        <Form.Item
          label="Student Group"
          name="gradeid"
          rules={[{ required: true, message: "Group required" }]}
        >
          {grades && (
            <Select
              value={sgrade}
              onChange={gradeChanged}
              placeholder="Please choose group"
            >
              {grades.map((g) => (
                <Select.Option key={g.id} value={g.id}>
                  {g.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Student mobile phone"
          name="gsm"
          rules={[{ required: true, message: "Mobile phone required" }]}
        >
          <Input placeholder="Please type student phone" autoComplete="off" />
        </Form.Item>
        {/* <Form.Item
          label="Parent Fullname"
          name="pname"
          rules={[{ required: true, message: "Parent fullname required" }]}
        >
          <Input placeholder="Please type parent fullname" />
        </Form.Item>
        <Form.Item
          label="Parent email address"
          name="pemail"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input placeholder="Please type Parent email" />
        </Form.Item> */}
        <Form.Item
          label="Gender of student"
          name="gender"
          rules={[{ required: true, message: "This field required" }]}
        >
          <Select
            onChange={gomChanged}
            value={gom}
            placeholder="Select gender of student"
          >
            <Select.Option key="male" value={1}>
              Male
            </Select.Option>
            <Select.Option key="female" value={2}>
              Female
            </Select.Option>
          </Select>
        </Form.Item>

        {/* <Form.Item
          label="Student Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password required!",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
              message:
                "Your password must be 6-25 digits, contain letters and numbers",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Student password again"
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords did not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Password again" />
        </Form.Item> */}
        <Form.Item label="Parents">
          <Form.List name="parents">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "fullname"]}
                      fieldKey={[fieldKey, "fullname"]}
                      rules={[
                        { required: true, message: "Missing fullname name" },
                      ]}
                    >
                      <Input placeholder="Parent fullname" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "phone"]}
                      fieldKey={[fieldKey, "phone"]}
                      rules={[{ required: true, message: "Missing phone" }]}
                    >
                      <Input placeholder="Parent Phone" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "email"]}
                      fieldKey={[fieldKey, "email"]}
                      rules={[
                        { required: true, message: "Missing email" },
                        { type: "email" },
                      ]}
                    >
                      <Input placeholder="Parent Email" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Parent
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button htmlType="reset">Clear</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewStudent;
